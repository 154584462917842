exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".file-2xg6q {\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n\n.fileIcon-3emfQ {\n  margin-right: 6px;\n}\n\n.container-VzIe1 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n}\n\n.fileTooltip-1KGqv {\n  max-width: 300px;\n  word-wrap: break-word;\n}\n", ""]);

// exports
exports.locals = {
	"file": "file-2xg6q",
	"fileIcon": "fileIcon-3emfQ",
	"container": "container-VzIe1",
	"fileTooltip": "fileTooltip-1KGqv"
};